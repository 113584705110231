import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/query-keys'
import { ApiService } from '@/services'
import { ReferralDto } from '~shared/types'

export const useGetReferral = ({
  referralId,
  token,
}: {
  referralId: string
  token: string
}) => {
  const getReferral = async () => {
    return await ApiService.extend({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .get(`v1/referrals/${referralId}`)
      .json<ReferralDto>()
  }

  return useQuery({
    queryKey: queryKeys.referral(referralId),
    queryFn: getReferral,
  })
}

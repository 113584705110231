import { Image, Text, VStack } from '@chakra-ui/react'

import NotFoundSvg from 'assets/not-found.svg'

import { HAS_SUPPORT_EMAIL } from '~shared/team.constants'

export const NotFoundPage = () => {
  return (
    <VStack
      w="full"
      h="full"
      justifyContent="center"
      alignItems="stretch"
      flex={1}
    >
      <VStack
        maxW="600px"
        mx="auto"
        paddingY={8}
        spacing={6}
        justify="center"
        align="center"
        textStyle="body-2"
        textAlign="center"
      >
        <Image src={NotFoundSvg} alt="not-found" />
        <VStack paddingX={8}>
          <Text textStyle="h5">{"We can't find that page."}</Text>
          <Text>{`If you're sure it should be here, contact us at ${HAS_SUPPORT_EMAIL}`}</Text>
        </VStack>
      </VStack>
    </VStack>
  )
}

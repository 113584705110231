import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { sizes } from './sizes'
import { space } from './space'
import { zIndices } from './z-index'

export const foundations = {
  colors,
  space,
  sizes,
  breakpoints,
  zIndices,
  radii: {
    default: '6px',
  },
}

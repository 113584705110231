import { useMemo } from 'react'
import { Text, VStack } from '@chakra-ui/react'

export const ChasFormFooter = ({ referralId }: { referralId: string }) => {
  const { prefix, suffix } = useMemo(() => {
    const prefix = referralId.slice(0, 8)
    const suffix = referralId.slice(9) // skip first dash
    return { prefix, suffix }
  }, [referralId])

  return (
    <VStack
      w="100%"
      textTransform="uppercase"
      align="end"
      justifySelf="flex-end"
    >
      <Text
        fontSize={14}
        fontWeight="bold"
        color="interaction.critical.default"
      >
        {prefix}
      </Text>
      <Text textStyle="caption-1">{suffix}</Text>
    </VStack>
  )
}

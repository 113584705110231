export const overlay = {
  backgroundColor: 'rgba(58, 62, 70, 0.3)',
  position: 'absolute',

  width: '100%',

  transitionProperty: 'opacity',
  transitionDuration: '200ms',
  transitionTimingFunction: 'ease-in',
}

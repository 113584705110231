const preBookingLayoutCardBaseStyle = {
  maxW: ['100%', '100%', '25rem'],
  bgColor: 'white',
  borderRadius: [0, 0, '8px'],
  my: [0, 0, '3.75rem'],
  py: [0, 0, '2.5rem'],
  px: ['admin-app-px', 'admin-app-px', '2.5rem'],
  pb: '1.5rem',
  gap: ['2.5rem', '2.5rem', '3rem'],
  flexDirection: 'column',
  flex: 1,
}

export const patientFacingPage = {
  container: {
    maxW: 'patient-facing-page-width',
    w: '100%',
  },
  preBookingLayout: {
    card: {
      base: {
        ...preBookingLayoutCardBaseStyle,
      },
      wide: {
        ...preBookingLayoutCardBaseStyle,
        maxW: 'prebooking-wide-width',
      },
    },
  },
}

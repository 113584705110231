import { Outlet } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'

export const AppLayout = () => {
  return (
    <Flex flexDir={'column'} minH="100%">
      <Outlet />
    </Flex>
  )
}

import { isStringArray } from './utils'

import { PublicReferralDto } from '~shared/types'

export const extractStringByQuestion = ({
  questionFragment,
  referral,
}: {
  questionFragment: string
  referral: PublicReferralDto
}): string | undefined => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes(questionFragment.toLowerCase()),
  )?.answer

  if (typeof answer !== 'string') return undefined

  return answer
}

export const extractStringArrayByQuestion = ({
  questionFragment,
  referral,
}: {
  questionFragment: string
  referral: PublicReferralDto
}): string[] | undefined => {
  const answer = referral.formResponses.find(({ question }) =>
    question.toLowerCase().includes(questionFragment.toLowerCase()),
  )?.answer

  if (!answer || !isStringArray(answer)) return undefined

  return answer
}

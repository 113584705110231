import { Flex, Text } from '@chakra-ui/react'

import { ReferralAppointmentDetails } from './ReferralAppointmentDetails'

import { PublicReferralDto } from '~shared/types'

export const ReferralDetails = ({
  referral,
}: {
  referral: PublicReferralDto
}) => {
  return (
    <Flex alignItems="flex-start" gap={2} flexDir="column">
      <Text
        textStyle="body-2"
        fontWeight="600"
        letterSpacing="1px"
        color="base.content.default"
      >
        REFERRAL DETAILS
      </Text>
      <ReferralAppointmentDetails referral={referral} />
    </Flex>
  )
}

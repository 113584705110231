import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/query-keys'
import { ApiService } from '@/services'
import { PublicReferralDto } from '~shared/types'

export const usePublicReferral = (referralId: string) => {
  const getPublicReferral = async () => {
    return await ApiService.get(
      `v1/public/referrals/${referralId}`,
    ).json<PublicReferralDto>()
  }

  return useQuery({
    queryKey: queryKeys.publicReferral(referralId),
    queryFn: getPublicReferral,
  })
}

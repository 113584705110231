import { theme } from '@chakra-ui/react'

export const ADMIN_APP_PX_IN_REM = 2
export const ADMIN_APP_MOBILE_PX_IN_REM = 1

export const space = {
  ...theme.space,
  'admin-app-px': `${ADMIN_APP_PX_IN_REM}rem`,
  'admin-app-mobile-px': `${ADMIN_APP_MOBILE_PX_IN_REM}rem`,
  'admin-app-content-pt': '1.5rem',
  'admin-app-content-pb': '5rem',
}

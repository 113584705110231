export const colors = {
  text: {
    secondary: '#3C4764',
    link: '#276EF1',
  },
  grey: {
    50: '#F8F9F9',
    100: '#E9E9E9',
    200: '#BFC2C8',
    300: '#A0A0A0',
    400: '#848484',
    500: '#686868',
    600: '#535353',
    700: '#474747',
    800: '#3D3D3D',
    900: '#2E2E2E',
  },
  green: {
    50: '#F5FAFA',
    100: '#D6F0E7',
    200: '#7CCFB3',
    300: '#33B488',
    400: '#009762',
    500: '#00774E',
    600: '#005F3E',
    700: '#0A524B',
    800: '#00462E',
    900: '#003523',
  },
  purple: {
    50: '#FAF5FF',
    100: '#E9D8FD',
    200: '#D6BCFA',
    300: '#B794F4',
    400: '#9F7AEA',
    500: '#805AD5',
    600: '#6B46C1',
    700: '#553C9A',
    800: '#44337A',
    900: '#322659',
  },
  primary: {
    50: '#F7F9FE',
    100: '#E7EFFC',
    200: '#ACC7FA',
    300: '#82ABF7',
    400: '#568DF4',
    500: '#1361F0',
    600: '#0D4FCA',
    700: '#0041A3',
    800: '#093890',
    900: '#072A69',
  },
  yellow: {
    50: '#FFFFE5',
    100: '#FEFABF',
    200: '#FAE989',
    300: '#F6D85E',
    400: '#ECC44B',
    500: '#D69E2E',
    600: '#A66E1C',
    700: '#7D5412',
    800: '#633E0D',
    900: '#4C320B',
  },
  neutral: {
    100: '#FBFCFD',
    200: '#F0F0F1',
    300: '#E1E2E4',
    400: '#C9CCCF',
    500: '#ABADB2',
    600: '#999B9F',
    700: '#636467',
    800: '#48494B',
    900: '#242425',
  },
  secondary: {
    200: '#BABECB',
    300: '#BFBFBF',
    400: '#7B82A9',
  },
  utility: {
    feedback: {
      'warning-subtle': '#FFFFE5',
      'critical-subtle': '#FEF7F7',
      'success-subtle': '#F5FAFA',
      'info-subtle': '#F7FAFF',
    },
  },
  interaction: {
    main: {
      default: '#005DEA',
    },
    'main-subtle': {
      default: '#E7EFFC',
    },
    critical: {
      default: '#C03434',
    },
    'critical-subtle': {
      active: '#EE8080',
      default: '#FBE9E9',
    },
    'warning-subtle': {
      default: '#FFFFE5',
    },
    'success-subtle': {
      default: '#E2EEED',
    },
    sub: {
      default: '#5E6591',
    },
    'sub-subtle': {
      default: '#E9EAEE',
      active: '#9AA0B3',
    },
    support: {
      unselected: '#A0A4AD',
      unselectedStrong: '#666C7A',
      placeholder: '#838894',
    },
    tinted: {
      neutral: {
        hover: 'rgba(0, 0, 0, 0.1)',
        active: 'rgba(0, 0, 0, 0.2)',
      },
    },
  },
  base: {
    content: {
      medium: '#666C7A',
      strong: '#2C2E34',
      default: '#454953',
    },
    divider: {
      strong: '#BFC2C8',
      medium: '#EBEBEB',
      subtle: '#F4F6F6',
    },
    canvas: {
      'brand-subtle': '#F7FAFF',
    },
  },
  'admin-settings-content-bg': '#E5E5E5',
}

import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(isSameOrBefore)
export const generateTimeOptions = () => {
  // 24 hrs
  let start = dayjs(`${dayjs().format('YYYY-MM-DD')} 00:00`, 'YYYY-MM-DD HH:mm')
  const end = dayjs(`${dayjs().format('YYYY-MM-DD')} 23:55`, 'YYYY-MM-DD HH:mm')
  const times: string[] = []
  while (start.isSameOrBefore(end)) {
    times.push(start.format('h:mm a'))
    start = start.add(15, 'm')
  }
  return times
}

import { Controller, useFormContext } from 'react-hook-form'
import { FormControl } from '@chakra-ui/react'
import { FormLabel, Textarea } from '@opengovsg/design-system-react'

import { SimpleReferralFormFields } from '../types'

import { DateTimeField } from './DateTimeField'

export const AcceptField = () => {
  const { control } = useFormContext<SimpleReferralFormFields>()

  return (
    <>
      <DateTimeField />
      <FormControl>
        <FormLabel description="We will send this to the GP.">
          Notes for GP doctor
        </FormLabel>
        <Controller
          name="notes"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Textarea
              value={value}
              onChange={onChange}
              placeholder="Enter your notes here"
            />
          )}
        />
      </FormControl>
    </>
  )
}

import { Flex, VStack } from '@chakra-ui/react'

import { SimpleReferralForm } from './SimpleReferralForm'
import { SubmitSimpleReferralButton } from './SubmitSimpleReferralButton'

import { ReferralDto } from '~shared/types'

export const SimplePrivateForm = ({ referral }: { referral: ReferralDto }) => {
  return (
    <Flex
      w="full"
      h="full"
      p="2rem"
      flex="1"
      justifyContent="center"
      bg="base.canvas.alt"
    >
      <VStack
        bg="white"
        borderRadius="0.5rem"
        align="stretch"
        spacing="2rem"
        p="2rem"
      >
        <SimpleReferralForm referral={referral} />
        <SubmitSimpleReferralButton />
      </VStack>
    </Flex>
  )
}

export const sidePanel = {
  container: {
    transitionProperty: 'width, min-width',
    transitionDuration: '150ms',
    transitionTimingFunction: 'ease-in-out',
    boxShadow: 'inset 1px 0px 0px #EDEDED',

    background: 'white',
    zIndex: 11,
    right: 0,

    flexDirection: 'column',
    overflow: 'hidden',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}

import { calendarEvent } from './calendarEvent'
import { card } from './card'
import { dndRow } from './dndRow'
import { emptyPlaceholder } from './emptyPlaceholder'
import { modal } from './modal'
import { navLink } from './navLink'
import { overlay } from './overlay'
import { pageSection } from './pageSection'
import { patientFacingPage } from './patientFacingPage'
import { sidePanel } from './sidePanel'

export const layerStyles = {
  patientFacingPage,
  queueInfoCard: {
    p: '1.5rem',
    borderRadius: 'md',
    boxShadow: 'sm',
    align: 'stretch',
  },
  focusRing: {
    default: {
      _focusVisible: {
        boxShadow: 'none !important',
        outline: `2px solid var(--chakra-colors-utility-focus-default)`,
        outlineOffset: '0.125rem',
        _dark: {
          outline: `2px solid var(--chakra-colors-utility-focus-inverse)`,
        },
      },
    },
    inverse: {
      _focusVisible: {
        boxShadow: 'none !important',
        outline: `2px solid var(--chakra-colors-utility-focus-inverse)`,
        outlineOffset: '0.125rem',
      },
    },
  },
  navLink,
  overlay,
  pageSection,
  dndRow,
  sidePanel,
  emptyPlaceholder,
  calendarEvent,
  card,
  modal,
}

export type SimpleReferralFormFields = {
  decision: SimpleReferralDecisionEnum
  date?: Date
  time: string
  appointmentTime?: number
  notes?: string
  rejectionMessage?: string
}

export enum SimpleReferralDecisionEnum {
  ACCEPT = 'accept',
  REJECT = 'reject',
  UNDEFINED = '',
}

export const requiredRule = {
  required: 'This field is required.',
}

import { theme } from '@chakra-ui/react'

const ADMIN_HEADER_HEIGHT = '3.25rem'
const ADMIN_MOBILE_NAV_HEADER_HEIGHT = '3rem'
const GOV_BANNER_HEIGHT = '2rem'
export const PATIENT_FACING_PAGE_WIDTH_IN_REM = 39.375

export const sizes = {
  ...theme.sizes,
  'admin-header-height': ADMIN_HEADER_HEIGHT,
  'admin-mobile-nav-header-height': ADMIN_MOBILE_NAV_HEADER_HEIGHT,
  'admin-mobile-content-min-height': `calc(100vh - ${GOV_BANNER_HEIGHT} - ${ADMIN_MOBILE_NAV_HEADER_HEIGHT} - ${ADMIN_HEADER_HEIGHT})`,
  'admin-content-min-height': `calc(100vh - ${GOV_BANNER_HEIGHT} - ${ADMIN_HEADER_HEIGHT})`,
  'admin-settings-content-card-min-width': '60rem',
  'admin-sidebar-width': '16.75rem',
  'admin-mobile-sidebar-width': '13.75rem',
  'exclude-gov-banner-height': `calc(100vh - ${GOV_BANNER_HEIGHT})`,
  'patient-facing-page-width': `${PATIENT_FACING_PAGE_WIDTH_IN_REM}rem`,
  'prebooking-wide-width': `36.375rem`,
}

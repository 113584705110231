export const dndRow = {
  flex: {
    cursor: 'pointer',
    w: 'full',
    bg: 'white',
    overflow: 'hidden',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'base.divider.medium',
    justify: 'space-between',
    align: 'center',
    py: '12px',
    alignItems: 'center',
  },
  selected: {
    borderColor: 'base.divider.brand',
    borderWidth: '1px',
    bgColor: 'interaction.main-subtle.default',
  },
}

import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys)

const radioCard = definePartsStyle({
  container: {
    borderRadius: 'default',
    borderWidth: '1px',
    borderColor: 'base.divider.medium',
    px: '1rem',
    py: '0.75rem',
    boxSizing: 'border-box',
    _checked: {
      borderColor: 'base.divider.brand',
      borderWidth: '2px',
      px: 'calc(1rem - 1px)',
      py: 'calc(0.75rem - 1px)',
      backgroundColor: 'base.canvas.alt',
      _focusWithin: {
        outline: 'none',
      },
    },
  },
  control: {
    borderColor: 'base.content.strong',
  },
})

export const Radio = defineMultiStyleConfig({ variants: { radioCard } })

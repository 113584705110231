export const pageSection = {
  container: {
    height: '2.75rem',
  },
  title: {
    color: 'base.content.strong',
    textStyle: 'h5',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}

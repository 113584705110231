import { Divider, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react'
import dayjs from 'dayjs'

import HappyDoctorSvg from 'assets/happy-doctor.svg'

import { Info } from '../components/Info'

import { ReferralDto } from '~shared/types'
import { maskUin } from '~shared/utils/human-readable'

export const ConfirmedPage = ({ referral }: { referral: ReferralDto }) => {
  return (
    <Flex
      w="full"
      h="full"
      flex="1"
      justifyContent="center"
      bg="base.canvas.alt"
    >
      <VStack
        w="40rem"
        h="fit-content"
        bg="white"
        borderRadius="0.5rem"
        m="2rem"
        p="2rem"
        align="stretch"
        spacing="1rem"
      >
        <Image src={HappyDoctorSvg} boxSize="150px" mx="auto" />
        <Heading fontSize="24px" color="base.content.strong" textAlign="center">
          Referral confirmed!
        </Heading>
        <Text color="base.content.default" textAlign="center">
          We will notify the patient and GP doctor of the referral outcome and
          appointment details. No further action is required from you.
        </Text>
        <Divider />
        <Info section="Where" title={referral.offering.name} />
        <Info
          section="When"
          title={dayjs(referral.timeslotStartAt).format('dddd, DD MMMM YYYY')}
        >
          <Text textStyle="h6" color="base.content.strong">
            {dayjs(referral.timeslotStartAt).format('h:mm A')}
          </Text>
        </Info>
        <Info section="Patient" title={referral.patient.name}>
          <Text>{maskUin(referral.patient.uin)}</Text>
        </Info>
        <Info section="Referring GP" title={referral.sender.name}>
          <Text>{referral.referringDoctor.name}</Text>
        </Info>
      </VStack>
    </Flex>
  )
}

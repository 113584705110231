import { Center, Image, Text, VStack } from '@chakra-ui/react'

import HASLoading from '@/assets/has-loading.gif'

export const LoadingScreen = () => {
  return (
    <Center w="100%" h="100%" flexGrow={1}>
      <VStack spacing="2rem" px="1rem">
        <Image src={HASLoading} alt="Loading..." h="5rem" />
        <Text
          color="base.content.medium"
          textStyle="subhead-1"
          textAlign="center"
        >
          Loading content...
        </Text>
      </VStack>
    </Center>
  )
}

import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'

import { SimplePrivateForm } from './components/SimplePrivateForm'
import { SimpleSubsidisedForm } from './components/SimpleSubsidisedForm'
import { ConfirmedPage } from './pages/ConfirmedPage'
import { ErrorPage } from './pages/ErrorPage'
import { RejectedPage } from './pages/RejectedPage'
import { WithdrawnPage } from './pages/WithdrawnPage'
import { SimpleReferralDecisionEnum, SimpleReferralFormFields } from './types'

import { LoadingScreen } from '@/components/LoadingScreen'
import { useGetReferral } from '@/hooks/useGetReferral'
import { uinFilter } from '~shared/utils/uin-filter'

export const SimpleReferralPage = () => {
  const { referralId } = useParams()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('auth') ?? ''

  const {
    data: referral,
    isPending,
    isError,
  } = useGetReferral({ referralId: referralId ?? '', token })

  useEffect(() => {
    if (!referral) return
    document.title = `${referral.patient.name} - ${uinFilter(
      referral.patient.uin,
    )}`
  }, [referral])

  const formMethods = useForm<SimpleReferralFormFields>({
    defaultValues: { decision: SimpleReferralDecisionEnum.ACCEPT, time: '' },
    mode: 'onSubmit',
  })

  if (isPending) return <LoadingScreen />
  if (isError) return <ErrorPage />

  if (referral.status === 'confirmed')
    return <ConfirmedPage referral={referral} />
  if (referral.status === 'rejected')
    return <RejectedPage referral={referral} />
  if (['pending-cancelled', 'cancelled'].includes(referral.status))
    return <WithdrawnPage referral={referral} />
  if (referral.status !== 'pending-sr-coordinator') return <ErrorPage />

  return (
    <FormProvider {...formMethods}>
      {referral.isSubsidised ? (
        <SimpleSubsidisedForm referral={referral} />
      ) : (
        <SimplePrivateForm referral={referral} />
      )}
    </FormProvider>
  )
}

import dayjs from 'dayjs'

// Localised text formats used for display
export enum DATE_DISPLAY_FORMATS {
  FULL_DATE_TIME = 'MMM D, YYYY h:mm A',
  DATE_ONLY = 'MMM D, YYYY',
}

export const titleCase = (str: string): string => {
  return str
    .trim()
    .split(/\s+/)
    .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

// distance in metres
export const formatDistance = (distance?: number): string => {
  if (!distance) return `0m`
  const unit = distance >= 1000 ? 'km' : 'm'
  const fixed =
    unit === 'km' ? +(distance / 1000).toFixed(1) : +distance.toFixed(0)
  return Intl.NumberFormat('en-US').format(fixed) + unit
}

export const formatDate = ({
  date,
  format = DATE_DISPLAY_FORMATS.FULL_DATE_TIME,
}: {
  date: string | Date
  format?: DATE_DISPLAY_FORMATS
}) => {
  const parsedDate = dayjs(date)
  // Edge-case: weird strings are returned as-is
  if (!parsedDate.isValid()) return date.toString()
  return dayjs(date).format(format)
}

const MODAL_PADDING_X = '1.5rem'

export const modal = {
  content: {},
  header: {
    whiteSpace: 'pre-wrap',
    marginBottom: '0',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingY: '1rem',
    paddingX: MODAL_PADDING_X,
    borderBottom: '1px solid',
    borderColor: 'base.divider.medium',
    gap: '1rem',
  },
  closeBtn: {
    color: 'base.content.strong',
    position: 'unset',
    '&:focus-visible, &[data-focus-visible]': {
      boxShadow: 'none',
      outline: 'none',
    },
    '& > svg': {
      fontSize: '1.5rem',
    },
  },
  body: {
    paddingX: MODAL_PADDING_X,
    gap: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  footer: {
    paddingX: MODAL_PADDING_X,
    paddingY: '1rem',
    borderTop: '1px solid',
    borderColor: 'base.divider.medium',
    zIndex: 1,
  },
  ctaContainer: {
    flexDirection: { base: 'column-reverse', md: 'row' },
    flex: 1,
    gap: '1rem',
    w: 'full',
    justifyContent: { base: 'stretch', md: 'flex-end' },
  },
}

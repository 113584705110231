import { FormFieldType, UpdateFormFieldsReq } from './form-fields.dto'

/**
 * One of the form fields is special - it is a large plaintext.
 * All offerings should have it.
 * It is used to send PB the initial note.
 */
export const NOTES_FORM_TITLE = `Referral notes`

export const DEFAULT_FORM_FIELDS: UpdateFormFieldsReq['formFields'] = [
  {
    title: NOTES_FORM_TITLE,
    fieldType: FormFieldType.LongText,
    description: `Enter any relevant medical notes`,
  },
]

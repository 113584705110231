export type NhgdCodeInfo = {
  code: string
  name: string
  description?: string
}

export type NhgdCodeCategories = {
  [category: string]: NhgdCodeInfo[]
}

export enum NhgdClinicalRadiologyPurpose {
  Screening = 'Screening',
  Others = 'Others',
}

export enum NhgdClinicalLabTestType {
  Blood = 'Blood',
  Urine = 'Urine',
  Others = 'Others',
}

export enum NhgdClinicalLabFasting {
  Yes = 'Yes',
  No = 'No',
}

export enum NhgdClinicalLabPurpose {
  Screening = 'Screening',
  Diagnosis = 'Diagnosis',
}

export enum NhgdPaymentMode {
  Patient = 'Bill patient',
  Clinic = 'Bill clinic',
}

export enum NhgdReportMaterials {
  Report = 'Report only',
  ReportAndCd = 'Report and CD',
  ReportAndFilms = 'Report and films',
}

export enum NhgdCollectionMode {
  Patient = 'Patient to collect',
  Dispatch = 'Dispatch to clinic',
}

export enum NhgdPriority {
  Routine = 'Routine',
  Stat = 'STAT',
}

export enum NhgdLastMenstrualPeriodReason {
  Na = 'Not applicable',
  UptNegative = 'Urine Pregnancy Test result is negative',
  Declaration = 'Patient declined Urine Pregnancy Test and declared that she is not pregnant',
}

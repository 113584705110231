import { useFormContext } from 'react-hook-form'
import { Button } from '@chakra-ui/react'
import dayjs from 'dayjs'

import { SimpleReferralFormFields } from '../types'

import { useSubmitSimpleReferral } from '@/hooks/useSubmitSimpleReferral'

export const SubmitSimpleReferralButton = () => {
  const { handleSubmit } = useFormContext<SimpleReferralFormFields>()

  const { onSubmit, isPending } = useSubmitSimpleReferral()

  const onClick = handleSubmit(async (data) => {
    const date = dayjs(data.date).format('YYYY-MM-DD')
    data.appointmentTime = dayjs(`${date} ${data.time}`).valueOf()
    await onSubmit(data)
  })

  return (
    <Button onClick={onClick} isLoading={isPending}>
      Submit
    </Button>
  )
}

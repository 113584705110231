import { Button, ButtonGroup, Flex } from '@chakra-ui/react'

import { SubmitSimpleReferralButton } from './SubmitSimpleReferralButton'

export const SubmitFooter = ({ children }: { children?: JSX.Element }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      background="white"
      bottom={0}
      boxShadow="0px -1px 6px rgba(0, 0, 0, 0.15)"
      padding="1rem"
      right={0}
      width="100%"
      position="fixed"
      zIndex={10}
    >
      {children}
      <ButtonGroup alignSelf="end">
        <Button variant="outline" onClick={() => window.print()}>
          Save as PDF
        </Button>
        <SubmitSimpleReferralButton />
      </ButtonGroup>
    </Flex>
  )
}

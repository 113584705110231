import { Transform, TransformFnParams } from 'class-transformer'
import { IsOptional } from 'class-validator'

export type PaginatedRes<T> = {
  data: T[]
  metadata: {
    hasNext: boolean
    totalCount: number
    nextParams?: PaginatedQuery
  }
}

/** Expected in query parameters on paginated GET requests. Build your class by extending this base. */
export class PaginatedQuery {
  // TODO: fix transform to int, now accept as number string
  @IsOptional()
  @Transform(({ value }: TransformFnParams) => {
    if (typeof value === 'undefined') return undefined
    if (typeof value === 'string') return parseInt(value) || 0
    return value
  })
  offset = 0

  // TODO: fix transform to int, now accept as number string
  @IsOptional()
  @Transform(({ value }: TransformFnParams) => {
    if (typeof value === 'undefined') return undefined
    if (typeof value === 'string') return parseInt(value) || 10
    return value
  })
  pageSize = 10
}

import { useMemo } from 'react'
import { Box, Flex, FlexProps, Image, Text, VStack } from '@chakra-ui/react'

import NhgdLogo from '@/assets/nhgd-logo.png'

export type NhgHeaderProps = FlexProps & {
  title: string
  referralId: string
  showHeaderOnlyOnPrint?: boolean
}

const PRINT_HEADER_CLASS_NAME = 'print-header'

export const NhgdLayout = ({
  title,
  referralId,
  children,
  showHeaderOnlyOnPrint,
  ...rest
}: NhgHeaderProps) => {
  // Visiually highlight just 8 digits for easier reading
  // relies on UUID format including dashes
  const { prefix, suffix } = useMemo(() => {
    const prefix = referralId.slice(0, 8)
    const suffix = referralId.slice(9) // skip first dash
    return { prefix, suffix }
  }, [referralId])

  const printStyles = `
    @media print {
      .${PRINT_HEADER_CLASS_NAME} {
        display: flex;
      }
    }`

  return (
    <Flex
      px={10}
      w="full"
      alignItems="stretch"
      flexShrink={0}
      maxWidth="210mm"
      mx="auto"
      flexDir="column"
      py={4}
      gap={2}
      style={{ breakBefore: 'always', breakInside: 'avoid' }}
      {...rest}
    >
      <style>{printStyles}</style>
      <Box
        className={PRINT_HEADER_CLASS_NAME}
        alignItems="center"
        gap={4}
        display={showHeaderOnlyOnPrint ? 'none' : 'flex'}
      >
        <Image flexShrink={0} src={NhgdLogo} width="8rem" />
        <VStack gap={0}>
          <Text fontWeight="bold" textTransform="uppercase">
            {title}
          </Text>
          <Text
            textStyle="body-2"
            lineHeight="1rem"
            fontSize="10px"
          >{`Please bring along your Identity Card / Work Pass / Social Visit Pass / Dependent's Pass / Birth Certificate / Passport or any legal documents for verification during registration.`}</Text>
        </VStack>
        <VStack textTransform="uppercase" align="center" textAlign="center">
          <Text textStyle="h4" color="interaction.critical.default">
            {prefix}
          </Text>
          <Text textStyle="caption-1">{suffix}</Text>
        </VStack>
      </Box>
      {children}
    </Flex>
  )
}

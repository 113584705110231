import { Type } from 'class-transformer'
import {
  ArrayNotEmpty,
  IsArray,
  IsBoolean,
  IsEnum,
  IsOptional,
  IsString,
  Length,
  ValidateNested,
} from 'class-validator'

import { IsStringOrStringListOrNumber } from '../decorators/is-string-or-stringlist-or-number.decorator'

export const FORM_FIELD_TITLE_MAX_LEN = 255
export const FORM_FIELD_DESCRIPTION_MAX_LEN = 1000

export enum FormFieldType {
  Date = 'date',
  ShortText = 'shortText',
  LongText = 'longText',
  Radio = 'radio',
  Dropdown = 'dropdown',
  MultiSelect = 'multiSelect',
  Number = 'number',
  Email = 'email',
}

export class FormResponse {
  @IsString()
  question: string

  @IsString()
  id: string

  @IsStringOrStringListOrNumber()
  answer: string | string[] | number
}

export class CreateFormFieldReq {
  @IsOptional()
  @IsString()
  id?: string

  @IsEnum(FormFieldType)
  fieldType: FormFieldType

  @IsString()
  @Length(1, FORM_FIELD_TITLE_MAX_LEN, {
    message: `Please use questions that are less than ${FORM_FIELD_TITLE_MAX_LEN} characters long.`,
  })
  title: string

  @IsString()
  @Length(0, FORM_FIELD_DESCRIPTION_MAX_LEN, {
    message: `Please use question descriptions that are less than ${FORM_FIELD_DESCRIPTION_MAX_LEN} characters long.`,
  })
  description: string

  @IsBoolean()
  isOptional? = false
}

export class UpdateFormFieldReq extends CreateFormFieldReq {}

export type DescriptiveFieldOptions = Array<DescriptiveOption>

export type DescriptiveOption = {
  value: string
  description?: string
}

class CreateFieldWithOptionsReq<
  T extends FormFieldType,
> extends CreateFormFieldReq {
  declare fieldType: T

  @ArrayNotEmpty()
  fieldOptions: string[]
}

class CreateFieldWithDescriptiveOptionsReq<
  T extends FormFieldType,
> extends CreateFormFieldReq {
  declare fieldType: T

  @ArrayNotEmpty()
  fieldOptions: DescriptiveFieldOptions
}

class CreateTextFieldReq<T extends FormFieldType> extends CreateFormFieldReq {
  declare fieldType: T
}

export class CreateDateFieldReq extends CreateTextFieldReq<FormFieldType.Date> {}
export class CreateEmailFieldReq extends CreateTextFieldReq<FormFieldType.Email> {}
export class CreateShortTextFieldReq extends CreateTextFieldReq<FormFieldType.ShortText> {}
export class CreateLongTextFieldReq extends CreateTextFieldReq<FormFieldType.LongText> {}
export class CreateNumberFieldReq extends CreateTextFieldReq<FormFieldType.Number> {}
export class CreateRadioFieldReq extends CreateFieldWithOptionsReq<FormFieldType.Radio> {}
export class CreateDropdownFieldReq extends CreateFieldWithDescriptiveOptionsReq<FormFieldType.Dropdown> {}
export class CreateMultiSelectFieldReq extends CreateFieldWithDescriptiveOptionsReq<FormFieldType.MultiSelect> {}

export type CreateFieldReq =
  | CreateDateFieldReq
  | CreateShortTextFieldReq
  | CreateLongTextFieldReq
  | CreateRadioFieldReq
  | CreateDropdownFieldReq
  | CreateMultiSelectFieldReq
  | CreateNumberFieldReq
  | CreateEmailFieldReq

export class UpdateFormFieldsReq {
  @IsArray()
  @ValidateNested()
  @Type(() => UpdateFormFieldReq, {
    discriminator: {
      property: 'fieldType',
      subTypes: [
        { value: CreateDateFieldReq, name: 'date' },
        { value: CreateLongTextFieldReq, name: 'longText' },
        { value: CreateShortTextFieldReq, name: 'shortText' },
        { value: CreateRadioFieldReq, name: 'radio' },
        { value: CreateDropdownFieldReq, name: 'dropdown' },
        { value: CreateMultiSelectFieldReq, name: 'multiSelect' },
        { value: CreateNumberFieldReq, name: 'number' },
        { value: CreateEmailFieldReq, name: 'email' },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  formFields: CreateFieldReq[]
}

export type GetFormFieldRes = CreateFieldReq

import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { ChasReferralForm } from './chas/ChasReferralForm'
import { DefaultReferralView } from './DefaultReferralView/DefaultReferralView'
import { NhgdLabForm, NhgdRadiologyForm } from './nhg'

import { LoadingScreen } from '@/components/LoadingScreen'
import { usePublicReferral } from '@/hooks/usePublicReferral'
import { ViewTemplate } from '~shared/types'
import { uinFilter } from '~shared/utils/uin-filter'

export const PublicReferralPage = () => {
  const { referralId } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const { data, isPending, isError } = usePublicReferral(referralId ?? '')

  useEffect(() => {
    if (!data) return
    document.title = `${data.patient.name} - ${uinFilter(data.patient.uin)}`
  }, [data])

  // TODO - better states
  if (isError) return <LoadingScreen />
  if (isPending) return <LoadingScreen />

  /* TODO: @thamsimun clean up - remove duplicacy in components if possible and
   allow nhgd form to still show the form even if some fields are not found
   just like chas form */

  switch (queryParams.get('variant')) {
    case ViewTemplate.NhgdLab:
      return <NhgdLabForm referral={data} />
    case ViewTemplate.NhgdRadiology:
      return <NhgdRadiologyForm referral={data} />
    case ViewTemplate.Chas:
      return <ChasReferralForm referral={data} />
    default:
      return <DefaultReferralView referral={data} />
  }
}

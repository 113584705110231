export const emptyPlaceholder = {
  py: '0.5rem',
  px: '1.25rem',
  w: 'full',
  overflow: 'hidden',
  border: 'dashed 1px',
  borderColor: 'secondary.200',
  borderRadius: '8px',
  justify: 'space-between',
  align: 'center',
  minHeight: '52px',
  spacing: '0.25rem',
  textStyle: 'body-2',
  color: 'base.content.medium',
}

export enum ClinicEnrolmentStatus {
  NotApplicable = 'Not Applicable',
  Yes = 'Yes',
  No = 'No',
}

export enum HealthierSGEnrolmentStatus {
  NotEnrolled = 'Not Enrolled',
  Enrolled = 'Enrolled',
}

export enum ClinicType {
  ChasOnly = 'CHAS-only',
  ChasAndHsg = 'CHAS and HSG',
}

export enum ChasReferralType {
  SubsidisedSOCFastTrack = 'Subsidised SOC (Fast-track)',
  SubsidisedSOCRoutine = 'Subsidised SOC (Routine)',
  CervicalCancerScreening = 'Cervical Cancer Screening (SFL)',
  BreastCancerScreening = 'Breast Cancer Screening (SFL)',
}

export enum ChasReferralRelation {
  Chas = 'CHAS',
  SFLScreening = 'SFL Screening',
  SFLFollowUp = 'SFL Follow-Up',
  VCDSSFollowUp = 'VCDSS Follow-Up',
  NoneOfTheAbove = 'None of the above',
}

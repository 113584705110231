export const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 5,
  dropdown: 10,
  sticky: 15,
  popover: 20,
  skipLink: 25,
  navHeader: 30,
  banner: 30,
  overlay: 40,
  modal: 45,
  toast: 50,
  tooltip: 60,
}

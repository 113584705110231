import { Flex, Heading, VStack } from '@chakra-ui/react'

import { PatientDetails } from './PatientDetails'
import { ReferralDetails } from './ReferralDetails'
import { ReferralRequest } from './ReferralRequest'

import { PublicReferralDto } from '~shared/types'

export const DefaultReferralView = ({
  referral,
}: {
  referral: PublicReferralDto
}) => {
  return (
    <Flex py={4} justifyContent="center">
      <VStack px={10} maxWidth="210mm" alignItems="stretch" flexShrink={0}>
        <Heading>Referral Form</Heading>
        <Flex
          gap={8}
          justifyContent="flex-start"
          flexDir="column"
          alignItems="flex-start"
        >
          <PatientDetails referral={referral} />
          <ReferralDetails referral={referral} />
          <ReferralRequest referral={referral} />
        </Flex>
      </VStack>
    </Flex>
  )
}

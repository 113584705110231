import {
  Box,
  Divider,
  Flex,
  HStack,
  ListItem,
  OrderedList,
  Text,
  VStack,
} from '@chakra-ui/react'

import { bodyStyle, borders, headerStyle } from '../chas-layer-styles'

import { formatDate } from '@/utils/human-readable'

export const ChasFormDoctorAcknowledgement = ({
  mcr,
  name,
  date,
}: {
  mcr?: string
  name?: string
  date?: string
}) => {
  return (
    <Flex flexDir="column">
      <Box {...borders} {...headerStyle}>
        Part IV: Doctor&apos;s Acknowledgement
      </Box>
      <Box {...borders} {...bodyStyle}>
        <OrderedList padding={5}>
          <ListItem>My clinic is a CHAS clinic</ListItem>
          <ListItem>
            I understand that with this subsidised referral, my patient would be
            assigned to the care of any specialist/doctor at the Referred
            Institution or Provider.
          </ListItem>
          <ListItem>
            I understand that my patient would be discharged back to my care/my
            clinic by the Referred Institution or Provider.
          </ListItem>
          <ListItem>
            I understand that <b>non-CHAS cardholders</b> are only eligible for
            subsidised SOC referral if referrals are made in line with the
            eligibility criteria and referral guidelines for Screen for Life
            (SFL) and Vaccination and Childhood Developmental Screening subsidy
            scheme.
          </ListItem>
          <ListItem>
            I understand that only <b>HSG patients enrolled to my clinic</b> and
            referred using this form to (a) another CHAS GP for cervical cancer
            screening or (b) a SFL breast cancer screening centre will receive
            fully-subsidised screening.
          </ListItem>
        </OrderedList>
        <HStack paddingX={10} justifyContent="space-between">
          <VStack gap={0} alignItems="flex-start">
            <Text>{name}</Text>
            <Divider width="220px" borderColor="black" />
            <Text>Name & Signature of Practitioner</Text>
          </VStack>
          <VStack gap={0} alignItems="flex-start">
            <Text>{mcr}</Text>
            <Divider width="150px" borderColor="black" />
            <Text>MCR No.</Text>
          </VStack>
          <VStack gap={0} alignItems="flex-start">
            <Text>{date && formatDate({ date })}</Text>
            <Divider width="150px" borderColor="black" />
            <Text>Date and Time</Text>
          </VStack>
        </HStack>
      </Box>
    </Flex>
  )
}

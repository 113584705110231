import { Divider, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react'

import PaisehDoctorSvg from 'assets/paiseh-doctor.svg'

import { Info } from '../components/Info'

import { ReferralDto } from '~shared/types'
import { maskUin } from '~shared/utils/human-readable'

export const RejectedPage = ({ referral }: { referral: ReferralDto }) => {
  return (
    <Flex
      w="full"
      h="full"
      flex="1"
      justifyContent="center"
      bg="base.canvas.alt"
    >
      <VStack
        w="40rem"
        h="fit-content"
        bg="white"
        borderRadius="0.5rem"
        m="2rem"
        p="2rem"
        align="stretch"
        spacing="1rem"
      >
        <Image src={PaisehDoctorSvg} boxSize="150px" mx="auto" />
        <Heading fontSize="24px" color="base.content.strong" textAlign="center">
          Referral rejected
        </Heading>
        <Text color="base.content.default" textAlign="center">
          We will notify the patient and GP doctor of the referral outcome. No
          further action is required from you.
        </Text>
        <Divider />
        <Info section="Patient" title={referral.patient.name}>
          <Text>{maskUin(referral.patient.uin)}</Text>
        </Info>
        <Info section="Referring GP" title={referral.sender.name}>
          <Text>{referral.referringDoctor.name}</Text>
        </Info>
      </VStack>
    </Flex>
  )
}

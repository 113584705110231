export const headerStyle = {
  fontWeight: 'bold',
  textTransform: 'uppercase',
  bg: 'interaction.tinted.neutral.active',
  fontSize: '11px',
} as const

export const bodyStyle = {
  fontSize: '12px',
  whiteSpace: 'pre-wrap',
} as const

export const borders = {
  py: 1,
  px: 2,
  border: '1px',
  borderRight: '0px',
  borderBottom: '0px',
} as const

export const card = {
  container: {
    borderRadius: 'default',
    overflow: 'hidden',
    boxShadow: '0px 1px 3px rgba(37, 32, 31, 0.2)',
    p: '1rem',
    boxSizing: 'border-box',
    bg: 'white',
    w: 'full',
    cursor: 'pointer',
    _hover: {
      bgColor: { base: 'none', lg: 'interaction.sub-subtle.default' },
    },
    _checked: {
      borderColor: 'white',
      boxShadow: '0 0 0 3px',
      color: 'interaction.main.default',
      bg: 'white',
    },
  },
  badge: {
    height: '1.5rem',
    p: '0.25rem 0.5rem',
    bgColor: '#E5E6FF',
    textColor: '#676AEB',
    width: 'fit-content',
  },
  header: {
    textStyle: 'h6',
    fontWeight: 500,
  },
  subhead: {
    textStyle: 'subhead-1',
    fontWeight: 500,
  },
  body: {
    textStyle: 'body-2',
    fontWeight: 400,
    color: 'base.content.medium',
  },
}

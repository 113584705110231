import { useState } from 'react'
import { BiZoomIn, BiZoomOut } from 'react-icons/bi'
import { Box, Flex, HStack, VStack } from '@chakra-ui/react'
import { Banner, IconButton } from '@opengovsg/design-system-react'

import { SimpleReferralForm } from './SimpleReferralForm'
import { SubmitFooter } from './SubmitFooter'

import { ChasReferralForm } from '@/features/public-referral/chas/ChasReferralForm'
import { ReferralDto } from '~shared/types'

const HIDDEN_CLASS = 'hidden-class'
const SHOW_CLASS = 'show-class'

export const SimpleSubsidisedForm = ({
  referral,
}: {
  referral: ReferralDto
}) => {
  const [zoom, setZoom] = useState(100)

  const printStyles = `
   .${SHOW_CLASS} {
     display: initial;
   }
   @media print {
     .${SHOW_CLASS} {
       display: none;
     }
   }
   .${HIDDEN_CLASS} {
     display: none;
   }
   @media print {
     .${HIDDEN_CLASS} {
       display: initial;
     }
   }`

  return (
    <>
      <style>{printStyles}</style>
      <Box className={HIDDEN_CLASS}>
        <Flex
          w="fit-content"
          h="fit-content"
          bg="white"
          p="1.5rem"
          mx="auto"
          align="stretch"
        >
          <ChasReferralForm referral={referral} />
        </Flex>
      </Box>
      <Box className={SHOW_CLASS}>
        <Banner>
          Please review the patient’s CHAS form, then accept or reject the
          referral.
        </Banner>
        <Flex h="calc(100vh - 116px)" overflow="hidden">
          <Flex
            h="full"
            flex="1"
            bg="base.canvas.backdrop"
            overflow="auto"
            p="2rem"
          >
            <Flex
              w="fit-content"
              h="fit-content"
              bg="white"
              p="1.5rem"
              mx="auto"
              align="stretch"
              style={{ zoom: `${zoom}%` }}
            >
              <ChasReferralForm referral={referral} />
            </Flex>
            <SubmitFooter>
              <HStack mx="auto">
                <IconButton
                  aria-label="zoom-out"
                  icon={<BiZoomOut />}
                  bg="white"
                  variant="outline"
                  colorScheme="neutral"
                  onClick={() => setZoom(Math.max(zoom - 10, 10))}
                />
                <IconButton
                  aria-label="zoom-in"
                  icon={<BiZoomIn />}
                  bg="white"
                  variant="outline"
                  colorScheme="neutral"
                  onClick={() => setZoom(Math.min(zoom + 10, 200))}
                />
              </HStack>
            </SubmitFooter>
          </Flex>
          <VStack w="400px" overflowY="auto" p="1.5rem" pb="2rem">
            <SimpleReferralForm referral={referral} />
          </VStack>
        </Flex>
      </Box>
    </>
  )
}

import { Flex, HStack, Image, Text } from '@chakra-ui/react'

import ChasLogo from '@/assets/chas-logo.jpg'
import HealthierSGLogo from '@/assets/healthiersg.webp'
import MohLogo from '@/assets/moh-logo.png'
import ScreenForLifeLogo from '@/assets/screenforlife.png'

export const ChasFormHeader = () => {
  return (
    <Flex flexDir="column">
      <HStack justifyContent="center" width="100%">
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          textAlign="center"
          alignSelf="center"
        >
          Community Health Assist Scheme (CHAS) <br /> (Medical) Referral Form
        </Text>
      </HStack>
      <HStack justifyContent="center">
        <Image flexShrink={0} src={MohLogo} width="8rem" />
        <Image flexShrink={0} src={ChasLogo} width="8rem" />
        <Image flexShrink={0} src={HealthierSGLogo} width="8rem" />
        <Image flexShrink={0} src={ScreenForLifeLogo} width="8rem" />
      </HStack>
    </Flex>
  )
}

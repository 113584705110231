import { QueryClient } from '@tanstack/react-query'

const RETRIES_MAX = 3
const queryClient = new QueryClient()

queryClient.setDefaultOptions({
  queries: {
    // Returns true to indicate should retry
    retry: (failureCount) => {
      return failureCount < RETRIES_MAX
    },
  },
})

export { queryClient }

import { BiDetail } from 'react-icons/bi'
import { HStack, Text, VStack } from '@chakra-ui/react'

import { Info } from './Info'

import { ReferralDto } from '~shared/types'
import { maskUin } from '~shared/utils/human-readable'

export const PatientDetails = ({ referral }: { referral: ReferralDto }) => {
  return (
    <VStack w="full" align="start" spacing="1rem">
      <HStack color="base.content.medium">
        <BiDetail fontSize="20px" />
        <Text textStyle="subhead-3" fontSize="16px">
          DETAILS
        </Text>
      </HStack>
      <Info section="Patient" title={referral.patient.name}>
        <Text>{maskUin(referral.patient.uin)}</Text>
      </Info>
      <Info section="Referring GP" title={referral.sender.name}>
        <Text>{referral.referringDoctor.name}</Text>
      </Info>
    </VStack>
  )
}

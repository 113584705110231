import { useFormContext } from 'react-hook-form'
import { Divider, Heading, Image, VStack } from '@chakra-ui/react'

import PaisehDoctorSvg from 'assets/paiseh-doctor.svg'

import { SimpleReferralDecisionEnum, SimpleReferralFormFields } from '../types'

import { AcceptField } from './AcceptField'
import { DecisionField } from './DecisionField'
import { PatientDetails } from './PatientDetails'
import { RejectField } from './RejectField'

import { ReferralDto } from '~shared/types'

export const SimpleReferralForm = ({ referral }: { referral: ReferralDto }) => {
  const { watch } = useFormContext<SimpleReferralFormFields>()
  const decision = watch('decision')

  return (
    <VStack w="min(100%, 40rem)" h="fit-content" align="stretch" spacing="2rem">
      <VStack align="stretch">
        <Image src={PaisehDoctorSvg} boxSize="150px" mx="auto" />
        <Heading fontSize="24px" color="base.content.strong" textAlign="center">
          Referral to {referral.offering.name}
        </Heading>
      </VStack>
      <Divider />
      <PatientDetails referral={referral} />
      <DecisionField />
      {renderAdditionalFields(decision)}
    </VStack>
  )
}

const renderAdditionalFields = (decision: SimpleReferralDecisionEnum) => {
  switch (decision) {
    case SimpleReferralDecisionEnum.ACCEPT:
      return <AcceptField />
    case SimpleReferralDecisionEnum.REJECT:
      return <RejectField />
    default:
      return <></>
  }
}

import { Box, Flex, FlexProps } from '@chakra-ui/react'

import { borders } from '../nhg-layer-styles'

export const Row = ({
  code,
  name,
  isChecked = false,
  ...rest
}: { code?: string; name: string; isChecked?: boolean } & FlexProps) => (
  <Flex
    textStyle="body-2"
    lineHeight="1rem"
    fontSize="10px"
    letterSpacing="-0.022em"
    {...rest}
  >
    <Box
      {...borders}
      p={0}
      w="3.25rem"
      letterSpacing="-0.05em"
      textAlign="center"
      position="relative"
    >
      {code}
      {isChecked && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          borderRadius="50%"
          width="3.75rem"
          height="1.1rem"
          borderColor="interaction.critical.default"
          borderWidth="2px"
        />
      )}
    </Box>
    <Box {...borders} px={1} py={0} flex={1}>
      {name}
    </Box>
  </Flex>
)

export const TitleRow = ({ title, ...rest }: FlexProps & { title: string }) => (
  <Row
    bg="interaction.tinted.neutral.active"
    name={title}
    textStyle="subhead-2"
    fontWeight="bold"
    {...rest}
  />
)

export const EmptyRow = (props: FlexProps) => (
  <Flex
    textStyle="body-2"
    lineHeight="1rem"
    fontSize="10px"
    letterSpacing="-0.022em"
    {...props}
  >
    <Box {...borders} px={1} py={0} w="3.25rem" />
    <Box
      {...borders}
      flex={1}
      px={1}
      py={0}
      textColor="rgba(0,0,0,0)"
      borderColor="black"
    >
      {'NIL'}
    </Box>
  </Flex>
)

import { Controller, useFormContext } from 'react-hook-form'
import { BiCommentEdit } from 'react-icons/bi'
import {
  FormControl,
  HStack,
  RadioGroup,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { FormErrorMessage, Radio } from '@opengovsg/design-system-react'

import {
  requiredRule,
  SimpleReferralDecisionEnum,
  SimpleReferralFormFields,
} from '../types'

export const DecisionField = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<SimpleReferralFormFields>()

  return (
    <FormControl isInvalid={!!errors.decision}>
      <VStack align="stretch" spacing="1rem">
        <HStack color="base.content.medium">
          <BiCommentEdit fontSize="20px" />
          <Text textStyle="subhead-3" fontSize="16px">
            REVIEW
          </Text>
        </HStack>
        <Controller
          name="decision"
          rules={requiredRule}
          control={control}
          render={({ field: { onChange, value } }) => (
            <RadioGroup value={value} onChange={onChange}>
              <Wrap w="full">
                <WrapItem flexGrow={1} minWidth="0">
                  <Radio
                    variant="radioCard"
                    value={SimpleReferralDecisionEnum.ACCEPT}
                  >
                    Accept referral
                  </Radio>
                </WrapItem>
                <WrapItem flexGrow={1} minWidth="0">
                  <Radio
                    variant="radioCard"
                    value={SimpleReferralDecisionEnum.REJECT}
                  >
                    Reject referral
                  </Radio>
                </WrapItem>
              </Wrap>
            </RadioGroup>
          )}
        />
      </VStack>
      <FormErrorMessage>{errors.decision?.message}</FormErrorMessage>
    </FormControl>
  )
}

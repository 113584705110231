import React from 'react'
import { IconType } from 'react-icons'
import { Box, HStack, Icon, Text, VStack } from '@chakra-ui/react'

export const Info = ({
  section,
  title,
  icon,
  children,
  textTransform,
}: {
  section?: string
  title?: string
  icon?: typeof Icon | IconType
  children?: React.ReactNode
  // capitalize by default
  textTransform?: 'capitalize' | 'none'
}) => {
  return (
    <HStack
      spacing="1.5rem"
      data-testid="info"
      alignItems="flex-start"
      w="full"
    >
      {section && (
        <Text
          textStyle="subhead-1"
          minW="7rem"
          maxW="7rem"
          color="base.content.strong"
        >
          {section}
        </Text>
      )}
      <HStack w="calc(100% - 8.5rem)">
        {icon && (
          <Box mr={4}>
            <Icon as={icon} mt="2px" boxSize="18px" />
          </Box>
        )}
        <VStack spacing="0.25rem" alignItems="left" w="full">
          {title && (
            <Text
              textStyle="h6"
              color="base.content.strong"
              textTransform={textTransform ?? 'capitalize'}
            >
              {title}
            </Text>
          )}
          {children}
        </VStack>
      </HStack>
    </HStack>
  )
}

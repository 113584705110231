import { registerDecorator, ValidationOptions } from 'class-validator'

export const IsStringOrStringListOrNumber = (
  validationOptions?: ValidationOptions,
) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (object: Object, propertyName: string) => {
    registerDecorator({
      name: 'isStringOrStringListOrNumber',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: unknown) {
          if (typeof value === 'string' || typeof value === 'number') {
            return true
          } else if (
            Array.isArray(value) &&
            value.every((item) => typeof item === 'string')
          ) {
            return true
          }
          return false
        },
      },
    })
  }
}

import { useCallback } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useToast } from '@opengovsg/design-system-react'
import { useMutation } from '@tanstack/react-query'

import { queryKeys } from '@/constants/query-keys'
import {
  SimpleReferralDecisionEnum,
  SimpleReferralFormFields,
} from '@/features/simple-referral/types'
import { ApiService } from '@/services'
import { queryClient } from '@/utils/query'
import { ReferralDto } from '~shared/types'

export const useSubmitSimpleReferral = () => {
  const { referralId } = useParams()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('auth')

  const { mutate: accept, isPending: isPendingAccept } =
    useAcceptSimpleReferral()
  const { mutate: reject, isPending: isPendingReject } =
    useRejectSimpleReferral()

  const toast = useToast()

  const onSubmit = useCallback(
    async (fields: SimpleReferralFormFields) => {
      const toastCallback = {
        onSuccess: () => {
          toast({
            status: 'success',
            description: 'Response saved.',
          })
        },
        onError: (e: Error) => {
          toast({
            status: 'error',
            description: e.message ?? 'Something went wrong.',
          })
        },
      }
      if (fields.decision === SimpleReferralDecisionEnum.ACCEPT) {
        accept(
          {
            referralId,
            token,
            ...fields,
          },
          toastCallback,
        )
      } else {
        reject(
          {
            referralId,
            token,
            ...fields,
          },
          toastCallback,
        )
      }
    },
    [referralId, accept, reject, toast, token],
  )
  return { onSubmit, isPending: isPendingAccept || isPendingReject }
}

const useAcceptSimpleReferral = () => {
  return useMutation({
    mutationFn: async ({
      referralId,
      appointmentTime,
      notes,
      token,
    }: {
      referralId?: string
      appointmentTime?: number
      notes?: string
      token: string | null
    }) => {
      const data = await ApiService.extend({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .post(`v1/referrals/${referralId}/accept/sr`, {
          json: { appointmentTime, notes },
        })
        .json<ReferralDto>()
      return data
    },
    onSuccess: async (_, { referralId }) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.referral(referralId ?? ''),
      })
    },
  })
}

const useRejectSimpleReferral = () => {
  return useMutation({
    mutationFn: async ({
      referralId,
      rejectionMessage,
      token,
    }: {
      referralId?: string
      rejectionMessage?: string
      token: string | null
    }) => {
      const data = await ApiService.extend({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .post(`v1/referrals/${referralId}/reject/sr`, {
          json: { rejectionMessage },
        })
        .json<ReferralDto>()
      return data
    },
    onSuccess: async (_, { referralId }) => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.referral(referralId ?? ''),
      })
    },
  })
}

import { Box, Flex, HStack, Text } from '@chakra-ui/react'

import { bodyStyle, borders, headerStyle } from '../chas-layer-styles'
import { ChasFormReferralDetails } from '../extract-chas-form-fields'

import { formatDate } from '@/utils/human-readable'
import {
  ChasReferralRelation,
  ChasReferralType,
} from '~shared/form-fields/chas/chas-form-fields.dto'

export const ChasFormReferralInfo = (info: ChasFormReferralDetails) => {
  return (
    <Flex flexDir="column">
      <Box {...borders} {...headerStyle}>
        Part II: Referral Information
      </Box>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Date of Referral`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.referralDate && formatDate({ date: info.referralDate })}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Type of Referral`}</b>
        </Box>
        <HStack {...borders} px={1} py={0} flex={1} flexWrap="wrap" rowGap={0}>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={
                info.referralType === ChasReferralType.SubsidisedSOCFastTrack
              }
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>Subsidised SOC (Fast-track)</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={
                info.referralType === ChasReferralType.SubsidisedSOCRoutine
              }
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>Subsidised SOC (Routine)</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={
                info.referralType === ChasReferralType.CervicalCancerScreening
              }
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>Cervical Cancer Screening (SFL)</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={
                info.referralType === ChasReferralType.BreastCancerScreening
              }
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>Breast Cancer Screening (SFL)</Text>
          </HStack>
          <i>
            <br />
            Note: Fast-track referrals should only be requested where more
            urgent review by the specialist is needed.
          </i>
        </HStack>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Reasons for Fast-track referral `}</b>
          <i>(if applicable)</i>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.fastTrackReferralReason}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Referred Institution or Provider (e.g. GP)`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.referredInstitutionOrProvider}
          <br /> <br />
          <i>
            Note: Referrals to another CHAS GP using this form is only for
            applicable for cervical cancer screenings
          </i>
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Specialty / Service`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.specialtyOrService}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Is this referral related to a CHAS / SFL / VCDSS visit?`}</b>
          <br />
          <i> (Select all that are applicable) </i>
        </Box>
        <HStack {...borders} px={1} py={0} flex={1} flexWrap="wrap" rowGap={0}>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.referralRelation?.includes(
                ChasReferralRelation.Chas,
              )}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>CHAS</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.referralRelation?.includes(
                ChasReferralRelation.SFLScreening,
              )}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>SFL Screening</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.referralRelation?.includes(
                ChasReferralRelation.SFLFollowUp,
              )}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>SFL Follow-Up</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.referralRelation?.includes(
                ChasReferralRelation.VCDSSFollowUp,
              )}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>VCDSS Follow-Up</Text>
          </HStack>
          <HStack>
            <input
              type="checkbox"
              readOnly
              checked={info.referralRelation?.includes(
                ChasReferralRelation.NoneOfTheAbove,
              )}
              style={{ filter: 'grayscale(100%)' }}
            />
            <Text>None of the above</Text>
          </HStack>
        </HStack>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Diagnosis (optional)`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.diagnosis}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Reasons for Referral`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.reasons}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Treatment / Management already provided by GP`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.treatmentOrManagementByGp}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Medical History`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.medicalHistory}
        </Box>
      </Flex>
      <Flex flexDir="row" {...bodyStyle}>
        <Box {...borders} px={1} py={0} width="24%">
          <b>{`Drug Allergies`}</b>
        </Box>
        <Box {...borders} px={1} py={0} flex={1}>
          {info.drugAllergies}
        </Box>
      </Flex>
    </Flex>
  )
}

export const textStyles = {
  h3: {
    fontWeight: 700,
    fontSize: '1.75rem',
    lineHeight: '2.25rem',
    letterSpacing: '-0.019em',
    fontFeatureSettings: "'tnum' on, 'cv05' on",
  },
  h4: {
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    letterSpacing: '-0.019em',
    fontFeatureSettings: "'tnum' on, 'cv05' on",
  },
  h5: {
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '1.72rem',
    letterSpacing: '-0.017em',
    fontFeatureSettings: "'tnum' on, 'cv05' on",
  },
  h6: {
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    letterSpacing: '-0.014em',
    fontFeatureSettings: "'tnum' on, 'cv05' on",
  },
  'display-1': {
    fontWeight: 700,
    fontSize: '4rem',
    lineHeight: '4.5rem',
    letterSpacing: '-0.022em',
    fontFeatureSettings: "'tnum' on, 'cv05' on",
  },
  'display-2': {
    fontWeight: 600,
    fontSize: '2.5rem',
    lineHeight: '3rem',
    letterSpacing: '-0.022em',
    fontFeatureSettings: "'tnum' on, 'cv05' on",
    mobile: {
      fontWeight: 600,
      fontSize: '2em',
      lineHeight: '2.5rem',
      letterSpacing: '-0.017em',
      fontFeatureSettings: "'tnum' on, 'cv05' on",
    },
  },
  'subhead-1': {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '-0.011em',
    fontFeatureSettings: "'tnum' on, 'cv05' on",
  },
  'caption-1': {
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontFeatureSettings: "'tnum' on, 'cv05' on",
  },
  'body-1': {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '-0.011em',
    fontFeatureSettings: "'tnum' on, 'cv05' on",
  },
  'body-2': {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    letterSpacing: '-0.006em',
    fontFeatureSettings: "'tnum' on, 'cv05' on",
  },
  'responsive-heading': {
    'heavy-1280': {
      fontWeight: 600,
      lineHeight: '3rem',
      fontSize: '2.5rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    'heavy-480': {
      fontWeight: 600,
      lineHeight: '2.5rem',
      fontSize: '2rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    heavy: {
      fontWeight: 600,
      lineHeight: '2rem',
      fontSize: '1.5rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    'light-1280': {
      fontWeight: 300,
      lineHeight: '3rem',
      fontSize: '2.5rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    'light-480': {
      fontWeight: 300,
      lineHeight: '2.5rem',
      fontSize: '2rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
    light: {
      fontWeight: 300,
      lineHeight: '2rem',
      fontSize: '1.5rem',
      letterSpacing: '-0.022em',
      fontFamily: 'body',
    },
  },
}

import { NOTES_FORM_TITLE } from '../default-form-fields'
import { CreateFieldReq, FormFieldType } from '../form-fields.dto'

import {
  NhgdClinicalLabFasting,
  NhgdClinicalLabPurpose,
  NhgdClinicalLabTestType,
  NhgdCodeCategories,
  NhgdCollectionMode,
  NhgdPaymentMode,
  NhgdPriority,
} from './phas-form-fields.dto'

const footnotes: { [key: number]: string } = {
  1: 'Fasting required (8 to 12 hours fasting).',
  2: 'For GP referral, test is acceptable only Mon to Fri before 11.30am',
  3: 'Test result available within an hour.',
  4: 'NA for lab processing service.',
  5: 'Time sensitive test.',
}

export const NHGD_LAB_TEST_CODES: NhgdCodeCategories = {
  diabetes: [
    {
      code: 'LBC04758',
      name: 'Diabetes Monitoring (with ACR)',
      description: [footnotes[1], footnotes[5]].join(' '),
    },
    {
      code: 'LBC06807',
      name: 'Hypertensive Panel (with ACR)',
      description: [footnotes[1], footnotes[5]].join(' '),
    },
    {
      code: 'LBC00441',
      name: 'Glucose, capillary',
      description: [footnotes[3], footnotes[4]].join(' '),
    },
    {
      code: 'LBC00450',
      name: 'Glucose, venous',
      description: footnotes[2],
    },
    {
      code: 'LBC12903',
      name: 'HbA1c',
      description: footnotes[3],
    },
    {
      code: 'LBC00614',
      name: 'Lipid Panel',
      description: footnotes[1],
    },
    {
      code: 'LBC00737',
      name: 'OGTT (2 points)',
      description: [footnotes[1], footnotes[4]].join(' '),
    },
    {
      code: 'LBC00439',
      name: 'OGTT (3 points)',
      description: [footnotes[1], footnotes[4]].join(' '),
    },
  ],
  stool: [
    { code: 'LBC00018', name: 'Acid Fast Bacilli Smear' },
    { code: 'LBC05276', name: 'Faecal Fat' },
    {
      code: 'LBC00426',
      name: 'Fungus smear',
      description: footnotes[3],
    },
    { code: 'LBC12826', name: 'Gram Stain' },
    { code: 'LBC01004', name: 'Genital Aerobic Culture' },
    { code: 'LBC00713', name: 'Gonococcus Culture' },
    { code: 'LBC00863', name: 'Respiratory aerobic Culture' },
    { code: 'LBC00870', name: 'Rotavirus' },
    { code: 'LBC00892', name: 'Stool Culture' },
    { code: 'LBC00754', name: 'Stool FEME' },
    {
      code: 'LBC12974',
      name: 'Stool Occult Blood',
      description: footnotes[4],
    },
    {
      code: 'LBC00522',
      name: 'Trichomonas wet mount (HVS swab)',
      description: footnotes[3],
    },
    { code: 'LBC12827', name: 'Scabies Scrape' },
    { code: 'LBC01043', name: 'Wound Aerobic Culture' },
  ],
  ecg: [
    {
      code: 'INV00001',
      name: '12 lead ECG',
      description: [footnotes[3], footnotes[4]].join(' '),
    },
  ],
  spirometry: [
    {
      code: 'INV00127',
      name: 'Spirometry (Post)',
      description: footnotes[4],
    },
    {
      code: 'INV00128',
      name: 'Spirometry (Pre/Post)',
      description: footnotes[4],
    },
  ],
  haematology: [
    { code: 'LBC00011', name: 'ABO RH Blood Grouping' },
    {
      code: 'LBC12891',
      name: 'APTT',
      description: [footnotes[2], footnotes[4], footnotes[5]].join(' '),
    },
    { code: 'LBC12900', name: 'ESR', description: footnotes[3] },
    {
      code: 'LBC05495',
      name: 'Full Blood Count',
      description: footnotes[3],
    },
    { code: 'LBC00643', name: 'Malaria Parasite Screen' },
    {
      code: 'LBC00773',
      name: 'Peripheral Blood Film',
      description: footnotes[3],
    },
    {
      code: 'LBC12915',
      name: 'Prothrombin Time (PTINR)',
      description: [footnotes[3], footnotes[4], footnotes[5]].join(' '),
    },
    { code: 'LBC00864', name: 'Reticulocytes' },
    {
      code: 'LBC00909',
      name: 'Thalassaemia Workup',
      description: footnotes[2],
    },
  ],
  urine: [
    {
      code: 'LBC00046',
      name: 'Albumin-Creatinine Ratio',
      description: footnotes[5],
    },
    { code: 'LBC00069', name: 'Amylase, Urine (Diastase)' },
    { code: 'LBC06703', name: 'Bence Jones Protein' },
    {
      code: 'LBC00993',
      name: 'Dipstick, Urine',
      description: [footnotes[3], footnotes[5]].join(' '),
    },
    { code: 'LBC12909', name: 'Osmolality, Urine' },
    {
      code: 'LBC00278',
      name: 'Protein-Creatinine Ratio',
      description: footnotes[5],
    },
    { code: 'LBC00997', name: 'Urine Culture', description: footnotes[5] },
    {
      code: 'LBC12922',
      name: 'Urine Microscopy',
      description: [footnotes[3], footnotes[5]].join(' '),
    },
    {
      code: 'LBC00535',
      name: 'Urine Pregnancy, HCG',
      description: footnotes[3],
    },
    { code: 'LBC06825', name: 'Urine Phase Contrast' },
    { code: 'LBC00227', name: 'Chloride (24hr urine)' },
    { code: 'LBC00270', name: 'Creatinine Clearance (24hr urine)' },
    { code: 'LBC00668', name: 'Microalbumin (24hr urine)' },
    { code: 'LBC12912', name: 'Potassium,  Urine (24hr urine)' },
    { code: 'LBC06191', name: 'Protein, Total (24hr urine)' },
    { code: 'LBC12917', name: 'Sodium (24hr urine)' },
    { code: 'LBC00982', name: 'Urea (24hr urine)' },
  ],
  kidney: [
    {
      code: 'LBC08840',
      name: 'ACEI/ARB Panel (K, Cre)',
      description: footnotes[5],
    },
    { code: 'LBC12896', name: 'Bicarbonate' },
    { code: 'LBC00273', name: 'Creatinine' },
    { code: 'LBC00224', name: 'Chloride' },
    {
      code: 'LBC00806',
      name: 'Electrolytes Panel (K, Na)',
      description: footnotes[5],
    },
    { code: '293', name: 'Kidney Function Panel', description: footnotes[5] },
    {
      code: 'LBC00803',
      name: 'Potassium',
      description: [footnotes[2], footnotes[5]].join(' '),
    },
    {
      code: 'LBC12916',
      name: 'Sodium',
      description: [footnotes[2], footnotes[5]].join(' '),
    },
    { code: 'LBC12921', name: 'Urea' },
  ],
  infectious: [
    {
      code: 'LBC01923',
      name: 'Antenatal Screening - FBC Package',
      description: footnotes[2],
    },
    { code: 'LBC01925', name: 'Antenatal Screen - Thalassaemia Package' },
    {
      code: 'LBC11317',
      name: 'Antenatal Uristix, Urine',
      description: footnotes[5],
    },
    { code: 'LBC06441', name: 'HIV Ab-Ag Screen' },
    { code: 'LBC04634', name: 'Dengue Test IgG, IgM, NS1Ag' },
    { code: 'LBC04639', name: 'Dengue IgG' },
    { code: 'LBC07802', name: 'Measles IgG' },
    { code: 'LBC07810', name: 'Measles IgM' },
    { code: 'LBC06808', name: 'Mumps IgG' },
    { code: 'LBC08245', name: 'Mumps IgM' },
    { code: 'LBC00871', name: 'Rubella IgG' },
    { code: 'LBC00872', name: 'Rubella IgM' },
    { code: 'LBC12099', name: 'SARS-COV2 Total Ab' },
    { code: 'LBC10437', name: 'Syphilis Screen' },
    { code: 'LBC10960', name: 'Toxoplasma IgG' },
    { code: 'LBC10980', name: 'Toxoplasma IgM' },
    { code: 'LBC06826', name: 'Varicella-Zoster IgG' },
    { code: 'LBC11620', name: 'Zika Virus RNA PCR (Blood)' },
    { code: 'LBC11653', name: 'Zika Virus RNA PCR (Urine)' },
  ],
  liver: [
    { code: 'LBC12892', name: 'Alanine Transaminase' },
    { code: 'LBC00047', name: 'Albumin / Total Protein' },
    { code: 'LBC00056', name: 'Alphafetoprotein' },
    { code: 'LBC12893', name: 'Alkaline Phosphatase' },
    { code: 'LBC12895', name: 'Aspartate Transaminase' },
    { code: 'LBC00493', name: 'Anti-HAV (IgM)' },
    { code: 'LBC00494', name: 'Anti-HAV (IgG)' },
    { code: 'LBC12324', name: 'Anti-HAV, Total' },
    { code: 'LBC00502', name: 'Anti-HBc (IgM)' },
    { code: 'LBC12829', name: 'Anti-HBc, Total' },
    { code: 'LBC00503', name: 'Anti-HBs' },
    { code: 'LBC02390', name: 'Anti-HBe' },
    { code: 'LBC06768', name: 'Anti-HCV' },
    { code: 'LBC06209', name: 'Anti-Hepatitis E IgG' },
    { code: 'LBC06220', name: 'Anti-Hepatitis E IgM' },
    { code: 'LBC12899', name: 'Bilirubin, Direct' },
    { code: 'LBC00561', name: 'Bilirubin, Indirect' },
    {
      code: 'LBC12919',
      name: 'Bilirubin, Total',
      description: footnotes[2],
    },
    {
      code: 'LBC00135',
      name: 'Bilirubin, Paeds',
      description: [footnotes[3], footnotes[4]].join(' '),
    },
    { code: 'LBC07649', name: 'BIL/ALP/ALB/TP' },
    { code: 'LBC12902', name: 'Gamma-Glutamyl Transferase' },
    { code: 'LBC00496', name: 'HBeAg' },
    { code: 'LBC00504', name: 'HBsAg' },
    { code: 'LBC08865', name: 'Hepatitis B Carrier Panel' },
    { code: 'LBC12904', name: 'Hepatitis B Screen' },
    { code: 'LBC00621', name: 'Liver Function Test' },
  ],
  singleTest: [
    { code: 'LBC00035', name: 'Adrenocorticotropic Hormone' },
    { code: 'LBC00051', name: 'Aldosterone' },
    { code: 'LBC06700', name: 'Aldolase' },
    { code: 'LBC01661', name: 'Aluminium' },
    { code: 'LBC01694', name: 'Amikacin (Trough / Peak)' },
    { code: 'LBC12894', name: 'Amylase' },
    { code: 'LBC06701', name: 'Anti-ds-DNA' },
    { code: 'LBC12822', name: 'Anti-Intrinsic Factor Antibody' },
    { code: 'LBC08157', name: 'Anti-Mitochondrial' },
    { code: 'LBC00087', name: 'Anti-Nuclear Antibody' },
    { code: 'LBC00076', name: 'Anti-Streptolysin O Titre' },
    { code: 'LBC00914', name: 'Anti-Thyroglobulin' },
    { code: 'LBC00917', name: 'Anti-Thyroid Peroxidase' },
    { code: 'LBC12823', name: 'Anti-Parietal cell Antibody' },
    {
      code: 'LBC00168',
      name: 'Calcium',
      description: footnotes[2],
    },
    {
      code: 'LBC12321',
      name: 'Calcium Panel',
      description: footnotes[2],
    },
    { code: 'LBC04180', name: 'Copper' },
    { code: 'LBC06446', name: 'Cortisol' },
    { code: 'LBC12824', name: 'C-Peptide' },
    { code: 'LBC12898', name: 'C-Reactive Protein' },
    {
      code: 'LBC00263',
      name: 'Creatine Kinase',
      description: footnotes[2],
    },
    { code: 'LBC00366', name: 'Estradiol' },
    { code: 'LBC00384', name: 'Ferritin' },
    { code: 'LBC06739', name: 'Fibrinogen' },
    { code: 'LBC00398', name: 'Folate' },
    { code: 'LBC00399', name: 'Folate, RBC' },
    { code: 'LBC00404', name: 'Follicular Stimulating Hormone' },
    { code: 'LBC06764', name: 'Fructosamine' },
    { code: 'LBC06766', name: 'G6PD Quantitative' },
    {
      code: 'LBC06767',
      name: 'Growth Hormone',
      description: footnotes[1],
    },
    { code: 'LBC06727', name: 'HLA-B*1502 Genotyping' },
    { code: 'LBC06786', name: 'Homocystine' },
    { code: 'LBC06794', name: 'Insulin' },
    {
      code: 'LBC06796',
      name: 'Insulin-Like Growth Factor',
      description: footnotes[1],
    },
    { code: 'LBC12905', name: 'Iron' },
    { code: 'LBC00583', name: 'Iron-TIBC Saturation' },
    { code: 'LBC00594', name: 'Lactate' },
    { code: 'LBC12906', name: 'Lactate Dehydrogenase' },
    {
      code: 'LBC07618',
      name: 'Lithium',
      description: footnotes[2],
    },
    { code: 'LBC06803', name: 'Luteinising Hormone' },
  ],
  cancer: [
    { code: 'LBC00184', name: 'CA 19-9' },
    { code: 'LBC05405', name: 'CH 50' },
    { code: 'LBC00181', name: 'CA 125' },
    { code: 'LBC00182', name: 'CA 15-3' },
    { code: 'LBC00196', name: 'Carcinoembryonic Antigen' },
    { code: 'LBC12914', name: 'Prostate Specific Antigen' },
    { code: 'LBC06824', name: 'Squamous Cell Carcinoma Antigen' },
    { code: 'LBC12907', name: 'Magnesium' },
    { code: 'LBC00744', name: 'Osmolality, serum' },
    { code: 'LBC06801', name: 'Parathyroid Hormone Intact' },
    { code: 'LBC12911', name: 'Phosphate' },
    { code: 'LBC12913', name: 'Prealbumin' },
    { code: 'LBC00813', name: 'Progesterone' },
    { code: 'LBC00814', name: 'Prolactin' },
    { code: '360', name: 'Cholinesterase, Serum' },
    { code: 'LBC00866', name: 'Rheumatoid Factor' },
    { code: 'LBC00906', name: 'Testosterone' },
    { code: 'LBC12828', name: 'Thyroglobulin Panel' },
    { code: 'LBC00956', name: 'Thyroxine (T4), Total' },
  ],
  drugs: [
    { code: 'LBC01295', name: 'Acetaminophen' },
    {
      code: 'LBC12897',
      name: 'Carbamazepine',
      description: footnotes[2],
    },
    {
      code: 'LBC00336',
      name: 'Digoxin',
      description: footnotes[2],
    },
    { code: 'LBC12825', name: 'Gentamicin' },
    {
      code: 'LBC00778',
      name: 'Phenobarbitone',
      description: footnotes[2],
    },
    {
      code: 'LBC12910',
      name: 'Phenytoin',
      description: footnotes[2],
    },
    { code: 'LBC12918', name: 'Theophylline' },
    { code: 'LBC11343', name: 'Vancomycin' },
    { code: 'LBC00416', name: 'Thyroxine, Free (FT4)' },
    { code: 'LBC00919', name: 'TSH' },
    { code: 'LBC01070', name: 'TSH Receptor Antibody' },
    { code: 'LBC12901', name: 'T3, Free' },
    { code: 'LBC00959', name: 'T3, Total' },
    { code: 'LBC12920', name: 'Transferrin' },
    { code: 'LBC00985', name: 'Uric Acid' },
    {
      code: 'LBC12923',
      name: 'Valproic Acid',
      description: footnotes[2],
    },
    { code: 'LBC01031', name: 'Vitamin B12' },
    { code: 'LBC12890', name: '25-Hydroxy Vitamin D' },
    { code: 'LBC06423', name: 'Zinc' },
  ],
}

export const NHGD_LAB_COMMON_QNS: CreateFieldReq[] = [
  {
    title: NOTES_FORM_TITLE,
    fieldType: FormFieldType.LongText,
    description: `Enter any relevant medical notes`,
  },
  {
    title: 'Does patient need to fast?',
    fieldType: FormFieldType.Dropdown,
    description: '',
    fieldOptions: Object.values(NhgdClinicalLabFasting).map((option) => ({
      value: option,
    })),
  },
  {
    title: 'Test Type',
    fieldType: FormFieldType.MultiSelect,
    description: '',
    fieldOptions: Object.values(NhgdClinicalLabTestType).map((option) => ({
      value: option,
    })),
  },
  {
    title: 'Priority',
    description: '',
    fieldType: FormFieldType.Radio,
    fieldOptions: Object.values(NhgdPriority),
  },
  {
    title: 'Purpose',
    description: '',
    fieldType: FormFieldType.Radio,
    fieldOptions: Object.values(NhgdClinicalLabPurpose),
  },
  {
    title: 'Payment',
    description:
      "If you select 'Bill Clinic', NHG Diagnostics will bill your GP clinic directly. This option is only available for GP clinics who have their billing details registered with NHG Diagnostics. Please write to asknhgd@diagnostics.nhg.com.sg to register your GP clinic with NHG Diagnostics.",
    fieldType: FormFieldType.Radio,
    fieldOptions: Object.values(NhgdPaymentMode),
  },
  {
    title: 'Collection',
    description:
      'If your GP clinic is not registered with NHG Diagnostics, the report will be given to the patient. Please write to asknhgd@diagnostics.nhg.com.sg to register your GP clinic with NHG Diagnostics.',
    fieldType: FormFieldType.Radio,
    fieldOptions: Object.values(NhgdCollectionMode),
  },
]

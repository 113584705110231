import { Flex, Heading, Image, Link, Text, VStack } from '@chakra-ui/react'

import PaisehDoctorSvg from 'assets/paiseh-doctor.svg'

import { HAS_SUPPORT_EMAIL } from '~shared/team.constants'

export const ErrorPage = () => {
  const href = `mailto:${HAS_SUPPORT_EMAIL}`

  return (
    <Flex
      w="full"
      h="full"
      flex="1"
      justifyContent="center"
      bg="base.canvas.alt"
    >
      <VStack
        w="40rem"
        h="fit-content"
        bg="white"
        borderRadius="0.5rem"
        m="2rem"
        p="2rem"
        align="stretch"
        spacing="1rem"
      >
        <Image src={PaisehDoctorSvg} boxSize="150px" mx="auto" />
        <Heading fontSize="24px" color="base.content.strong" textAlign="center">
          This URL doesn’t look right
        </Heading>
        <Text color="base.content.default" textAlign="center">
          Please write in to <Link href={href}>{HAS_SUPPORT_EMAIL}</Link> and we
          will send you the correct link.
        </Text>
      </VStack>
    </Flex>
  )
}

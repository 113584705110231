import { extendTheme } from '@chakra-ui/react'
import { theme as ogpTheme } from '@opengovsg/design-system-react'

import { components } from './components'
import { foundations } from './foundations'
import { layerStyles } from './layerStyles'
import { textStyles } from './textStyles'

export const theme = extendTheme(ogpTheme, {
  ...foundations,
  textStyles,
  layerStyles,
  components,
  styles: {
    global: {
      html: {
        height: '100%',
      },
      body: {
        height: '100%',
        fontFeatureSettings: "'tnum' on, 'cv05' on",
      },
      '#root, #__next': {
        height: 'inherit',
      },
    },
  },
})

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString()

export const PdfDocument = ({ file }: { file: string }) => {
  const [numPages, setNumPages] = useState<number>(0)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  return (
    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
      {[...Array(numPages).keys()]
        .map((page) => page + 1) // 1-indexed
        .map((page) => (
          <Page
            key={page}
            pageNumber={page}
            renderTextLayer
            renderAnnotationLayer
            width={800}
          />
        ))}
    </Document>
  )
}

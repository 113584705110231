import { ReactNode } from 'react'
import { Flex } from '@chakra-ui/react'

import { ChasFormFooter } from './ChasFormFooter'

const A4_HEIGHT = '282mm' // 297 minus 1.5cm margin for printing
const A4_WIDTH = '195mm' // 210 minus 1.5cm margin for printing

export const ChasPageLayout = ({
  children,
  referralId,
}: {
  referralId: string
  children: ReactNode
}) => {
  return (
    <Flex
      flexDir="column"
      minH={A4_HEIGHT}
      maxWidth={A4_WIDTH}
      w={A4_WIDTH}
      alignItems="center"
      flexShrink={0}
      justifyContent="space-between"
      style={{ pageBreakAfter: 'always' }}
    >
      <Flex flexDir="column">{children}</Flex>
      <ChasFormFooter referralId={referralId} />
    </Flex>
  )
}

import { StackProps, Text, VStack } from '@chakra-ui/react'

import { NhgdCommonFields } from '../extract-nhgd-lab-form-fields'
import { bodyStyle, borders } from '../nhg-layer-styles'

import { formatDate } from '@/utils/human-readable'
import { formatAddress } from '~shared/utils/human-readable'

export const NhgdSentBy = ({
  data,
  ...rest
}: { data: NhgdCommonFields } & StackProps) => {
  return (
    <VStack
      textStyle="caption-2"
      {...borders}
      {...bodyStyle}
      justifySelf="end"
      alignItems="flex-start"
      spacing={0}
      {...rest}
    >
      <b>{`Ordering Doctor:`}</b>
      <Text>{`${data.doctor.name} (MCR: ${data.doctor.mcr})`}</Text>
      <Text>{data.referringClinic.name}</Text>
      <Text>{formatAddress(data.referringClinic)}</Text>
      <Text
        fontStyle="italic"
        color="base.content.medium"
      >{`Referred on ${formatDate({ date: data.createdAt })}`}</Text>
    </VStack>
  )
}
